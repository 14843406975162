<template>
	<div data-route>
		<page-header
			:heading="getHeaderText"
		/>
		<div data-element="main">
			<validation-observer
				data-element="form"
				tag="form"
				ref="form"
				@submit.prevent="submit"
				v-if="getIsAuthenticated && !notVerified"
			>
				<input-field
					name="Password"
					type="password"
					autocomplete="new-password"
					rules="required|min:8"
					v-model="credentials.password"
				/>
				<input-field
					name="Confirm password"
					type="password"
					autocomplete="new-password"
					rules="required|confirmed:password"
					v-model="credentials.passwordConfirm"
				/>
			</validation-observer>
			<p
				data-element="text"
				v-if="notVerified"
			>
				Please try resetting your password again
			</p>
			<actions
				:actions="actions"
				@actionClick="onActionClick"
			/>
		</div>
	</div>
</template>

<route>
	{
		"meta": {
			"isPublic": true
		}
	}
</route>

<script>

	import PageHeader          from '@/components/ui/PageHeader';
	import InputField          from '@/components/forms/InputField';
	import Actions             from '@/components/ui/Actions';
	import invalidFormMessage  from '@/mixins/invalidFormMessage';
	import actionClick         from '@/mixins/actionClick';
	import api                 from '@/services/api';

	export default {
		metaInfo () {
			return {
				title: this.getHeaderText
			};
		},
		components: {
			PageHeader,
			InputField,
			Actions
		},
		mixins: [actionClick, invalidFormMessage],
		data: () => ({
			credentials: {
				email: '',
				password:         '',
				passwordConfirm:  ''
			},
			notVerified: false
		}),
		computed: {
			getIsAuthenticated () {
				return this.$store.getters['auth/getIsAuthenticated'];
			},
			getHeaderText () {
				return this.notVerified ? 'There was a problem resetting your password' : 'Choose a new password';
			},
			actions () {
				if (this.notVerified) {
					return {
						primary: [
							{
								text: 'Reset password',
								route: '/reset-password'
							}
						]
					};
				} else {
					return {
						primary: [
							{
								text: 'Set password',
								actionId: 'submit'
							},
							{
								text: 'Cancel',
								route: '/login'
							}
						],
						secondary: [
							{
								text: 'Contact support',
								route: '#'
							}
						]
					};
				}
			},
			getBreadcrumbs () {
				return [
					{
						path: '/new-password',
						text: 'Choose a new password'
					}
				];
			}
		},
		created () {
			this.$store.commit('ui/setBreadcrumbs', {
				breadcrumbs: this.getBreadcrumbs
			});
			if (!this.$route.query.code) {
				this.$store.commit('ui/showError');
				return;
			}
			if (this.getIsAuthenticated) {
				this.$store.commit('ui/showError');
				return;
			}
			this.logInWithCode();
		},
		methods: {
			async logInWithCode () {
				const login = await this.$store.dispatch('auth/logInWithCode', {
					code: this.$route.query.code
				});

				if (login === false) {
					this.notVerified = true;
				}
				await this.$store.commit('ui/setLoadingIsHidden');
			},
			async submit () {
				const valid = await this.$refs.form.validate();
				if (!valid) {
					this.showInvalidFormMessage();
					return;
				}
				const response = await api.auth.updateCurrentUserPassword({
					credentials: this.credentials
				});
				if (!response) {
					return;
				}
				// store the new JWT if it is present
				if (response.token) {
					await this.$store.dispatch('auth/setToken', {
						token: response.token
					});
				}
				await this.$router.push('/my-courses');
				this.$store.commit('ui/showNotification', {
					notification: 'Your password has been updated'
				});

			}
		}
	};

</script>

<style lang="scss" scoped>
	[data-element='text'] {
		font-size:rem(12);
		margin-bottom: 20px;
	}
</style>
